<template>
  <!--    :class="['input-field', {'input-error': !isValidate}, {success: successful}, {'d-block': block}]"-->
  <div :class="['input-field d-block', {'input-error': !isValidate}, {success: successful}]" :style="{width: styleWidth}" @focus="focus">
    <div>
      <textarea
        ref="textarea"
        :class="['', {block: block}]"
        :style="[{height: height ? height + 'px' : false}]"
        :rows="rows"
        :placeholder="placeholder"
        :value="value"
        :readonly="readonly"
        :tabindex="tabIndex ? tabIndex : false"
        :maxlength="maxLength ? maxLength : false"
        :disabled="disabled"
        v-on="eventListeners"
        v-if="multiline">
      </textarea>
      <input
        ref="input"
        :class="['', {block: block}, {'text-right': textRight}, {'text-right': textRight}]"
        :style="[{width: width ? width + 'px' : false}]"
        :type="type"
        :placeholder="placeholder"
        :value="value"
        :tabindex="tabIndex ? tabIndex : false"
        :disabled="disabled"
        :readonly="readonly"
        :maxlength="maxLength ? maxLength : false"
        v-on="eventListeners"
        autocomplete="off"
        v-else />
      </div>

    <div :class="['error-message', {error: errorTransition}]" v-if="message !== '' || successful">
      {{message}}
    </div>
  </div>
</template>

<script>
export default {
  name: 'inputField',
  props: {
    value: [String, Number],        // model
    type: {                         // 입력 폼 형식 String:''
      type: String,
      default: 'text'
    },
    placeholder: String,            // String:''
    rows: {                         // multiline 이 true인 경우 몇 줄 까지 보일지 설정 Number: 5
      type: [Number, String],
      default: 5
    },
    height: [String, Number],       // textarea 높이
    width: [String, Number],        // 넓이
    block: {                        // display block !!! width가 선언 된 경우 width가 우선
      type: Boolean,
      default: false
    },
    validate: {                     // 폼 검증 배열일 경우 함수에 의거, true일 경우 pattern 호출(password, email만) Array:[condition:function || message:text] or true
      type: Array,
      default: () => []
    },
    blurValidate: {
      type: Boolean,
      default: true
    },
    success: {                      // 폼 검증이 완료된 경우 check icon 표시 여부 Boolean: false
      type: Boolean,
      default: false
    },
    pattern: {                      // 체크할 패턴을 지정 String:''
      type: String,
      default: ''
    },
    maxLength: [String, Number],    // 최대 입력 가능한 글자 수 (type=text 에만 적용) String:''
    errorMessage: {                 // 강제 에러 출력 - check함수를 수행 하지 않음 String:''
      type: String,
      default: ''
    },
    multiline: {                    // textarea 여부,
      type: Boolean,
      default: false
    },
    disabled: Boolean,              // disabled : false
    autofocus: {
      type: Boolean,
      default: false
    },             // 자동 포커스
    textRight: String,              // text 정렬 오른 쪽으로
    tabIndex: [String, Number],     // tabindex
    readonly: Boolean,              // readonly Boolean: false,
    numberOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isValidate: true,
      checkPass: false,
      message: '',
      errorTransition: false
    }
  },
  watch: {
    errorMessage(v) {
      // 임의로 지정된 에러가 있는 경우 에러 아이콘 표기
      if (v != '') {
        this.message = v
        this.isValidate = false
        this.errorTransition = true
      } else {
        this.message = ''
        this.isValidate = true
        this.errorTransition = false
      }
    },
    errorTransition(v) {
      if (v) {
        setTimeout(() => {
          this.errorTransition = false
        }, 300)
      }
    },
    value(after, before) {
      // 외부에서 model이 업데이트 되도 유효성 검사
      if (after != '') {
        this.message = ''
        this.isValidate = true
        this.errorTransition = false
      }
    },
    validate() {
      this.message = ''
      this.isValidate = true
      this.errorTransition = false
    }
  },
  computed: {
    eventListeners() {
      return {
        ...this.$listeners,
        input: evt => {
          this.updateValue(evt)
        },
        blur: evt => {
          if (this.blurValidate) {
            this.check();
          }
        },
        keyup: evt => {
          if (this.numberOnly) {
            this.setNumberOnly(evt)
          }
        }
      }
    },
    successful() {
      return this.success === true && this.isValidate && this.checkPass
    },
    styleWidth() {
      if (this.width) {
        return (this.width.toString().indexOf('%') >= 0) ? this.width : this.width + 'px'
      } else {
        return false
      }
    }
  },
  created() {
    if (this.value != '') {
      this.updateValue()
    }
  },
  mounted() {
    if (this.autofocus) {
      if (this.multiline) {
        this.$refs.textarea.focus()
      } else {
        this.$refs.input.focus()
      }
    }
  },
  methods: {
    focus(evt) {
      // 포커스 이벤트 매칭
      if (this.multiline) {
        this.$refs.textarea.focus()
      } else {
        this.$refs.input.focus()
      }
    },
    updateValue(evt = null) {
      let v = ''

      if (evt === null) {
        v = this.value
      }
      else if (this.type === 'number' && this.value.toString().length >= this.maxLength) {
        v = this.value.toString().substr(0, this.maxLength -1)
      }
      else {
        v = evt.target.value
      }

      this.$emit('input', v)
    },
    getPattern(type = '') {
      if (this.pattern !== undefined) {
        type = this.pattern
      }

      let obj =  {
        pattern: '',
        message: ''
      }

      switch (type) {
        // 이메일: 형식에 맞지 않는 이메일 주소 입력 시
        case 'email.check':
          obj.pattern = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
          obj.message = this.$msg('email.check')
          break
        // 비밀번호: 형식에 맞지 않는 비밀번호 입력 시
        case 'password.check':
          /*obj.pattern = /^(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9])|(?=.*[a-zA-Z])(?=.*[0-9])|(?=.*[^a-zA-Z0-9])(?=.*[0-9]).{8,16}$/*/
          obj.pattern = /^(?=.*?[a-zA-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,20}$/ // 영문,숫자,특수문자 조합의 8 ~ 20자.
          obj.message = this.$msg('password.check')
          break
        // 닉네임
        case 'name.check':
          obj.pattern = /^([ㄱ-ㅎ|가-힣|a-z|A-Z|0-9|]){2,15}$/ // 2~15자 이내의 한글,영문,숫자만.
          obj.message = this.$msg('name.check')
          break
        // 휴대폰 번호
        case 'phone.check':
          obj.pattern = /^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})-?[0-9]{3,4}-?[0-9]{4}$/
          obj.message = this.$msg('phone.check')
          break
      }

      return obj
    },
    check() {
      // 임의로 지정된 에러가 없는 경우
      if (this.errorMessage === '') {
        // validate check
        if (this.validate.length > 0) {
          for (let i = 0; i < this.validate.length; i++) {
            let result = this.validate[i].call(null, this.value)

            if (result !== true) {
              this.message = result
              this.isValidate = false
              this.checkPass = false
              this.errorTransition = true

              return false
            } else {
              this.message = ''
            }
          }
        }

        this.isValidate = true
        this.checkPass = true

        return true
      } else {
        this.errorTransition = true
      }
    },
    resetForm() {
      this.$emit('input', '')
    },
    setNumberOnly(evt = null) {
      let v = ''

      if (evt === null) {
        v = this.value
      } else {
        v = evt.target.value
      }

      this.$emit('input', v.replace(/[^\d]/g,''))
    },
  }
}
</script>
